<template>

    <div id="showlastpositionmap" class="baseTemplate">

        <div>
            <div id="map" class="mapDiv assetsTrackingMapDiv leaflet-touch"></div>
        </div>

    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "./../../i18n";
import L from "leaflet";
import {} from "leaflet-imageoverlay-rotated";
import {} from "leaflet.fullscreen";
import commonVueHelper from '../../helpers/commonVueHelper';
const _countries = require("../../constants/countrygeo");

export default {
    data() {
        return {
            tagId: this.$route.params.tagId,
            leafletMap: null,
            currentMapLayer: null,
            floorSite: null,
            floorBuilding: null,
            floorObject: null,
            viewAssets: null,
            mapOptions: {
                attributionControl: false,
                fullscreenControl: false,
            },
            mapInitialized: false,
            tileLayer: null,
            circle: null,
            defaultLatitude: 26.689183497156236,
            defaultLongitude: -96.46957397460938,
            defaultZoom: 2,
            countriesByAlpha2: _.keyBy(_countries.countries, "country"),
            floorImageOverlay: null,
            assetsLayer: null,
            backgroundImageLayer: null,
            currentMapZoom: 20
        }
    },

    created() {
        console.log("Component(showlastpositionmap)::created() - called", this.tagId);

        this.getAssetLastPositionByTagId({tagId: this.tagId, locale: this.$i18n.locale});
    },

    mounted: function() {
        console.log("Component(showlastpositionmap)::mounted() - Init metronic layout");
        this.setIsLoading(false);
        // Init Metronic layout
        KTLayout.init();
        // Init tooltips on page
        $('[data-toggle="kt-tooltip"]').tooltip();
    },

    destroyed: function() {
        console.log("Component(showlastpositionmap)::destroyed() - called");
        // Reset location when map is destroyed
        this.resetFloorAssets();

        if (this.leafletMap) {
            if (this.tileLayer) {
                this.leafletMap.removeLayer(this.tileLayer);
                this.tileLayer = null;
            }
            if (this.circle) {
                this.leafletMap.removeLayer(this.circle);
                this.circle = null;
            }
            if (this.floorImageOverlay) {
                this.leafletMap.removeLayer(this.floorImageOverlay);
                this.floorImageOverlay = null;
            }
            if (this.currentMapLayer) {
                this.leafletMap.removeLayer(this.currentMapLayer);
                this.currentMapLayer = null;
            }
            if (this.assetsLayer) {
                this.leafletMap.removeLayer(this.assetsLayer);
                this.assetsLayer = null;
            }
            if (this.backgroundImageLayer) {
                this.leafletMap.removeLayer(this.backgroundImageLayer);
                this.backgroundImageLayer = null;
            }
            this.leafletMap = null;
        }
    },

    watch: {

        assetsPosition() {
            console.log("Component(showlastpositionmap)::watch(assetsPosition) - called with ", this.assetsPosition);
            // Init asset site, floor, building and asset position
            if (this.assetsPosition && this.assetsPosition.site) {
                this.floorSite = {...this.assetsPosition.site};
            }
            if (this.assetsPosition && this.assetsPosition.floor) {
                this.floorObject = {...this.assetsPosition.floor}
            }
            if (this.assetsPosition && this.assetsPosition.building) {
                this.floorBuilding = {...this.assetsPosition.building};
            }
        },

        floorSite(site) {
            console.log("Component(showlastpositionmap)::watch(floorSite) - called with ", site);
            if (site && !this.mapInitialized) {
                this.mapInitialized = true;
                // Init default map by default.
                this.defineSiteLocation(site);
            }
        },

        currentMapLatitude: function(latitude) {
            console.log("Component(showlastpositionmap)::watch(currentMapLatitude) - called with ", latitude);
            // site location has been defined => init of leaflet map
            if (!this.leafletMap && this.currentMapLatitude !== 0.01) {
                console.log("Component(showlastpositionmap)::watch(currentMapLatitude) - call initMap()");
                this.initMap();
            } else {
                console.log("Component(showlastpositionmap)::watch(currentMapLatitude) - call onCenterMap()");
                this.onCenterMap();
            }
        },

    },

    computed: {
        ...mapGetters([
            'assetsPosition',
            'floorsAsset',
            'currentMapLatitude',
            'currentMapLongitude',
        ])
    },

    methods: {
        ...mapActions(['resetFloorAssets', 'setMapLocation', 'setCurrentOpenedAssetIdInDashboard', 'getAssetLastPositionByTagId', 'setIsLoading']),

        // This function is used to inform map store if an update of latitude, longitude or zoom appears.
        notifyLocation(longitude, latitude, zoom) {
            const locationData = {
                longitude: longitude,
                latitude: latitude,
                zoom: zoom
            };
            this.setMapLocation(locationData);
        },

        // Define map location before initialization
        defineSiteLocation(site) {
            // If site already contain a location, init map with this location
            if (site.location && site.location.coordinates && site.zoom) {
                this.notifyLocation(
                    site.location.coordinates[0],
                    site.location.coordinates[1],
                    site.zoom
                );
            } else {
                // Site location has not been set for the moment.
                // We will try to focus map to the selected site country if he has been filled by the user. (Optional by default)
                if (site.name && site.city && site.country) {
                    let siteAddress =
                        site.name + " " + site.city + " " + site.country;
                    let url =
                        "https://nominatim.openstreetmap.org/search?format=json&limit=3&q=" +
                        siteAddress;
                    $.get(url, data => {
                        //console.log("site GPS coordinates : "+data);
                        if (data.length !== 0) {
                            for (let result of data) {
                                if (
                                    result.importance > 0.4 &&
                                    (result.class === "building" ||
                                        result.class === "amenity")
                                ) {
                                    this.notifyLocation(
                                        result.lon,
                                        result.lat,
                                        16
                                    );
                                    return;
                                }
                            }
                        }

                        let siteAddress = site.city + " " + site.country;
                        let url =
                            "https://nominatim.openstreetmap.org/search?format=json&limit=3&q=" +
                            siteAddress;
                        $.get(url, data => {
                            for (let result of data) {
                                if (
                                    result.importance > 0.5 &&
                                    (result.class === "place" ||
                                        result.class === "boundary")
                                ) {
                                    this.notifyLocation(
                                        result.lon,
                                        result.lat,
                                        12
                                    );
                                    break;
                                }
                            }
                        });
                    });
                }

                if (site.country) {
                    // A country has been selected by the user during site creation
                    let countryInfo = this.countriesByAlpha2[site.country];
                    if (countryInfo) {
                        // We have defaut lat/long for this site. Init map with these coordinates !
                        this.notifyLocation(
                            countryInfo.longitude,
                            countryInfo.latitude,
                            5
                        );
                    } else {
                        // We don't have any lat/long info for the selected country, init map with default parameters.
                        this.notifyLocation(
                            this.defaultLongitude,
                            this.defaultLatitude,
                            this.defaultZoom
                        );
                    }
                } else {
                    // User has not selected any country during site creation, init map with default parameters.
                    this.notifyLocation(
                        this.defaultLongitude,
                        this.defaultLatitude,
                        this.defaultZoom
                    );
                }
            }
        },

        // This function is used to init Leaflet map with site of default location data
        initMap() {
            console.log("Component(showfloorassetsonmap)::initMap() - called ");
            this.leafletMap = L.map("map", this.mapOptions).setView(
                [this.currentMapLatitude, this.currentMapLongitude],
                this.currentMapZoom
            );

            let openstreetmap = L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                {
                    maxZoom: 22,
                    maxNativeZoom: 18,
                    attribution:
                        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>'
                }
            );

            this.leafletMap.addLayer(openstreetmap)

            window.leafletMap = this.leafletMap;

            // Display the floor image
            this.displayFloorMap();

            // Display the assets on map
            this.showAssetsOnMap();
        },

        // This function is called when user clicks on the center map button
        onCenterMap() {
            console.log("Component(showfloorassetsonmap)::onCenterMap() - called");
            if (this.leafletMap) {
                this.leafletMap.setView(
                    [this.currentMapLatitude, this.currentMapLongitude],
                    this.currentMapZoom
                );
            }
        },

        /**
         * Method to filter floor asset according to map view.
         */
        showAssetsOnMap() {
            // Init array which will contains assets which are view
            if (this.leafletMap && this.floorsAsset) {
                this.assetsLayer = new L.FeatureGroup();
                this.assetsCircleRadiusLayer = new L.FeatureGroup();
                this.leafletMap.addLayer(this.assetsLayer);
                // Then, for each asset, create a marker layer and add it to the global gateways layer
                let assetMarker = L.marker([this.floorsAsset.tag.lastLocation.coordinates[0], this.floorsAsset.tag.lastLocation.coordinates[1]], this.getAssetIcon(this.floorsAsset));

                let tooltipHTML = this.getTooltipContent(this.floorsAsset);

                var tooltipObj = {
                    className: "assetTrackingTooltip",
                    permanent: true,
                    direction: "right"
                };
                
                tooltipObj.offset = new L.Point(15, -35);
                if (this.floorsAsset.assetCategoryIconSize === "SIZE_LARGE") {
                    tooltipObj.offset = new L.Point(19, -50);
                }
                assetMarker.bindTooltip(tooltipHTML, tooltipObj);

                // This line is important: we are setting the id of the marker layer using asset id.
                // It will be use full later to find a layer by id.
                assetMarker.options.id = this.floorsAsset.id;

                // to do, listen click on marker to open custom
                assetMarker.on("click", (e) => {
                    let assetId = e.target.options.id;
                    // Set currentOpenedAsset is store
                    this.setCurrentOpenedAssetIdInDashboard("");
                    this.setCurrentOpenedAssetIdInDashboard(assetId);
                });

                // Add marker to the map
                this.assetsLayer.addLayer(assetMarker);

                // Code to associate a small circle around the marker. Usefull for debug mode.
                let radiusCircleLayer = L.circle([this.floorsAsset.tag.lastLocation.coordinates[0], this.floorsAsset.tag.lastLocation.coordinates[1]], 3, {
                    color: "red"
                });
                this.assetsCircleRadiusLayer.addLayer(radiusCircleLayer);
            }
        },

        /**
         * Method called to get assets icon according asset category and availability (when applicable)
         * @param asset
         * @param labelName
         */
        getAssetIcon(asset) {
            let iconObj = {
                shadowUrl: null
            };
            iconObj.iconAnchor = new L.Point(20, 45);
            iconObj.iconSize = new L.Point(40, 45);

            if (asset.assetCategoryIconSize === "SIZE_LARGE") {
                iconObj.iconAnchor = new L.Point(26, 60);
                iconObj.iconSize = new L.Point(51, 60);
            }

            let iconUrl = "";
            if (asset.isDefaultCategory) {
                // If the asset category is a default category, use png image saved in public folder,
                // for performance reasons.
                const icon = asset.assetcategory ? asset.assetcategory.name : asset.categoryName ;
                iconUrl = "/assets/categories/" + icon + "/Icon_marker" + this.getAssetIconSizeExtension(asset) + this.getAssetAvailabilityExtension(asset) + ".png";
                iconObj.iconUrl = iconUrl;
                var generatedClass = "asset_" + asset.tag.macAddress;
                iconObj.className = "leaflet-autocalibrationtag-location-mouse-marker " + generatedClass;

                return {
                    icon: L.icon(iconObj)
                };
            } else {
                // If the category is a custom category, we will use the customicon base64 image
                iconUrl = asset.categoryImage;
                iconObj.iconUrl = iconUrl;

                var backgroundStyle = "";
                var iconStyle = "";
                var availabilityExt = this.getAssetAvailabilityExtension(asset);
                if (availabilityExt === "_vert") {
                    backgroundStyle = asset.assetCategoryIconSize === "SIZE_LARGE" ? "emptyDefaultMarkerModelGreenLarge" : "emptyDefaultMarkerModelGreenSmall";
                    iconStyle = asset.assetCategoryIconSize === "SIZE_LARGE" ? "trackingDashboardCustomIconCatWithAvailabilityLarge" : "trackingDashboardCustomIconCatWithAvailabilitySmall";
                } else if (availabilityExt === "_orange") {
                    backgroundStyle = asset.assetCategoryIconSize === "SIZE_LARGE" ? "emptyDefaultMarkerModelOrangeLarge" : "emptyDefaultMarkerModelOrangeSmall";
                    iconStyle = asset.assetCategoryIconSize === "SIZE_LARGE" ? "trackingDashboardCustomIconCatWithAvailabilityLarge" : "trackingDashboardCustomIconCatWithAvailabilitySmall";
                } else if (availabilityExt === "_rouge") {
                    backgroundStyle = asset.assetCategoryIconSize === "SIZE_LARGE" ? "emptyDefaultMarkerModelRedLarge" : "emptyDefaultMarkerModelRedSmall";
                    iconStyle = asset.assetCategoryIconSize === "SIZE_LARGE" ? "trackingDashboardCustomIconCatWithAvailabilityLarge" : "trackingDashboardCustomIconCatWithAvailabilitySmall";
                } else {
                    backgroundStyle = asset.assetCategoryIconSize === "SIZE_LARGE" ? "emptyDefaultMarkerModelLarge" : "emptyDefaultMarkerModelSmall";
                    iconStyle = asset.assetCategoryIconSize === "SIZE_LARGE" ? "trackingDashboardCustomIconCatLarge" : "trackingDashboardCustomIconCatSmall";
                }

                iconObj.className = "leaflet-autocalibrationtag-location-mouse-marker " + backgroundStyle;
                iconObj.html = '<img id="asset_' + asset.tag.macAddress + '" class="' + iconStyle + '" src="' + iconUrl + '" />';

                return {
                    icon: L.divIcon(iconObj)
                };
            }
        },

        /**
         * Method called to get suffix string used to specified to good icon url on the map according icon size of asssociated category
         * @param asset
         */
        getAssetIconSizeExtension(asset) {
            let iconSizeExtension = "";
            if (asset.assetCategoryIconSize && asset.assetCategoryIconSize === "SIZE_SMALL") {
                iconSizeExtension = "_45";
            }
            return iconSizeExtension;
        },

        /**
         * Method called to get suffix string used to specified to good icon url on the map according availability custom field value
         * @param asset
         */
        getAssetAvailabilityExtension(asset) {
            let iconUrlExtension = "";
            if (asset.customfields) {
                for (let i = 0; i < asset.customfields.length; i++) {
                    if (asset.customfields[i].name === "CUSTOMFIELD_AVAILABILITY") {
                        switch (asset.customfields[i].value) {
                            case "ENUM_AVAILABILITY_AVAILABLE":
                                iconUrlExtension = "_vert";
                                break;
                            case "ENUM_AVAILABILITY_UNAVAILABLE":
                                iconUrlExtension = "_rouge";
                                break;
                            default:
                                iconUrlExtension = "_vert";
                        }
                    }
                }
            }
            return iconUrlExtension;
        },

        /**
         * Method called to get tooltip HTML content according current asset to display and assets labels rows values
         * @param asset
         */
        getTooltipContent(asset) {
            let tooltipHTML = '<div class="assetTrackingTooltipContainer" data-assetid="' + asset.id + '" >';
            // Add name
            tooltipHTML += "<span>" + asset.assetName + "</span>";
            // Add asset category
            let value = "";
            if (asset.categoryName) {
                if (asset.isDefaultCategory) {
                    value = i18n.t(asset.categoryName);
                } else {
                    value = asset.categoryName;
                }
            } else {
                value = "N/A";
            }
            if (value !== "N/A") {
                tooltipHTML += "<span>" + value + "</span>";
            }
            // Close tooltip
            tooltipHTML += "</div>";

            return tooltipHTML;
        },

        /**
         * Method called to display the image of floor
         * @param asset
         */
        displayFloorMap() {
            if (this.floorObject) {
                let c1Coords, c2Coords, c3Coords;
                // Floor was loaded from backend, display it at the stored position
                c1Coords = {
                    lat: this.floorObject.mapCoordinates.coordinates[0][1],
                    lng: this.floorObject.mapCoordinates.coordinates[0][0]
                };
                c2Coords = {
                    lat: this.floorObject.mapCoordinates.coordinates[1][1],
                    lng: this.floorObject.mapCoordinates.coordinates[1][0]
                };
                c3Coords = {
                    lat: this.floorObject.mapCoordinates.coordinates[2][1],
                    lng: this.floorObject.mapCoordinates.coordinates[2][0]
                };

                // Get floorMap image from store
                let floorImage = new Image();

                // Use base64 image
                floorImage.src = this.floorObject.map;

                // Create floor overlay upon current LeafLet map
                this.floorImageOverlay = L.imageOverlay.rotated(floorImage, L.latLng(c1Coords.lat, c1Coords.lng), L.latLng(c2Coords.lat, c2Coords.lng), L.latLng(c3Coords.lat, c3Coords.lng), {
                    opacity: 1,
                    interactive: true
                });
                this.leafletMap.addLayer(this.floorImageOverlay);
                this.displayFloorAndBuildingName()
            }
        },

        /**
         * Display the name of building and floor
         */
        displayFloorAndBuildingName() {
            let html =`
            <div class="leaflet-bottom leaflet-left selectedFloorsList text-center">
                <ul id="selectedFloorsList">
                    <li class="leaflet-range-control leaflet-control currentBuildingsAndFloorName">
                        <span class="floorSelector_currentBuildingName">` +
                            this.floorBuilding.name +
                        `</span>
                            /
                        <span>` +
                        this.floorObject.name +
                        `</span> (` +
                        i18n.t("b&f_level") +
                        `: <span>` +
                        this.floorObject.floorNumber +
                        `</span>)
                    </li>
                </ul>
            </div>`;
            $(".leaflet-control-container").append(html);

            // At the end, display update number of assets currently displayed on the map
            let labelKeyAssetDisplayed = "asset_oneDisplayed";
            let numberOfAssets = this.floorsAsset ? 1 : 0;
            if (this.floorsAsset) {
                labelKeyAssetDisplayed = "asset_moreDisplayed";
            }
            let numberAssetsEl =
            `<div id="numberOfAssetsDiv">
                    <div class="numberOfAssetsDisplayed">
                        ( <span>` +
                numberOfAssets +
                `</span>` +
                " " +
                i18n.t(labelKeyAssetDisplayed) +
                ` )</div>
            </div>`;
            $(".currentBuildingsAndFloorName").append(numberAssetsEl);
            if (numberOfAssets) {
                const dateTime = moment(this.floorsAsset.tag.location_timestamp / 1000);
                const latestPositionTime = dateTime.format(commonVueHelper.getDateStringFormatFromLocale(this.$i18n.locale) + " [GMT]ZZ");
                const lastPosition =
                `<div id="lastPosition">
                    <div style="text-align:center; font-size: 12px;">
                        ${i18n.t("asset_latestPosition")}: ${latestPositionTime}
                    </div>
                </div>
                `;
                $(".currentBuildingsAndFloorName").append(lastPosition);
            }
        }
    },

    components: {
        // -- Components -- List of local components used in the current template
        // --
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.assetsTrackingMapDiv {
    height: calc(100vh - 5px);
    width: 100%;
}
</style>
